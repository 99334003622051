import * as React from "react";
import SimpleBlockContent from "../SimpleBlockContent";
import { CheckIcon } from "icons/components";

import type { TextWithUSPs as Props } from "../../types";

function TextWithUsps({ text, usps }: Props): JSX.Element {
	return (
		<div className="grid gap-10 grid-cols-1 m-auto px-6 py-20 max-w-6xl lg:gap-20 lg:grid-cols-12">
			<div className="col-span-full max-w-lg text-sm leading-relaxed lg:col-span-4">
				{text && <SimpleBlockContent blocks={text} />}
			</div>
			<div className="lg:col-start col-span-full space-y-2 lg:col-span-7">
				{usps.flatMap((item, i) => {
					if (!item) return [];

					return (
						<UniqueSellingPoint key={i}>{item.tagline}</UniqueSellingPoint>
					);
				})}
			</div>
		</div>
	);
}

export default TextWithUsps;

const UniqueSellingPoint: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
	<div className="flex items-center">
		<CheckIcon
			width={24}
			height={24}
			className="flex-shrink-0 mr-2 text-primary"
			aria-hidden
		/>
		<span className="font-rigBold text-sm font-bold uppercase">{children}</span>
	</div>
);
