import React, { Fragment } from "react";
import * as SectionComponents from "./sections";
import { Page, Service } from "../types/generated/schema";
import type { ValuesType } from "../types/utils";

type ResolveSectionsProps = {
	type:
		| Pick<ValuesType<Page["content"]>, "_type">["_type"]
		| Pick<ValuesType<Service["content"]>, "_type">["_type"];
};

function resolveSections({ type }: ResolveSectionsProps) {
	const Section = SectionComponents[type];

	if (Section) {
		return Section;
	}

	console.error("Cant find section", type); // eslint-disable-line no-console

	return null;
}

type RenderSectionsProps = {
	sections: Page["content"] | Service["content"];
};

function RenderSections({ sections }: RenderSectionsProps): JSX.Element {
	if (!sections) {
		console.error("Missing section");

		return <div>Missing sections</div>;
	}

	return (
		<Fragment>
			{sections.map((section) => {
				const SectionComponent = resolveSections({ type: section._type });
				if (!SectionComponent) {
					return <div>Missing section {section._type}</div>;
				}

				return <SectionComponent {...section} key={section._key} />;
			})}
		</Fragment>
	);
}

export default RenderSections;
