import * as React from "react";
import type { IconPicker } from "../types/generated/schema";
import {
	HouseIcon,
	BuildingsIcon,
	ConstructionHelmetIcon,
	KeysIcon,
	GroupIcon,
	FileIcon,
	ToolsIcon,
	CogIcon,
	FolderIcon,
} from "icons/components";

type Props = Pick<IconPicker, "icon"> & {
	width?: number;
	height?: number;
};

function IconPickerRenderer({
	icon,
	width = 24,
	height = 24,
	...props
}: Props): JSX.Element {
	switch (icon) {
		case "construction-helmet":
			return (
				<ConstructionHelmetIcon
					className="mb-2"
					width={width}
					height={height}
					{...props}
				/>
			);
		case "keys":
			return (
				<KeysIcon className="mb-2" width={width} height={height} {...props} />
			);
		case "buildings":
			return (
				<BuildingsIcon
					className="mb-2"
					width={width}
					height={height}
					{...props}
				/>
			);
		case "cog":
			return (
				<CogIcon className="mb-2" width={width} height={height} {...props} />
			);
		case "folder":
			return (
				<FolderIcon className="mb-2" width={width} height={height} {...props} />
			);
		case "group_people":
			return (
				<GroupIcon className="mb-2" width={width} height={height} {...props} />
			);
		case "file":
			return (
				<FileIcon className="mb-2" width={width} height={height} {...props} />
			);
		case "tools":
			return (
				<ToolsIcon className="mb-2" width={width} height={height} {...props} />
			);
		case "house":
			return (
				<HouseIcon className="mb-2" width={width} height={height} {...props} />
			);
		default:
			return null;
	}
}

export default IconPickerRenderer;
