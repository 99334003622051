import * as React from "react";

export function useInView(
	ref: React.RefObject<HTMLElement>,
	options: IntersectionObserverInit,
): boolean {
	const [isInView, setIsInView] = React.useState(false);

	React.useEffect(() => {
		if (!ref.current) return;

		const observer = new IntersectionObserver(
			(entries) => setIsInView(entries[0].isIntersecting),
			{
				threshold: options.threshold,
				root: options.root,
				rootMargin: options.rootMargin,
			},
		);

		observer.observe(ref.current);

		return () => observer.disconnect();
	}, [ref, options.threshold, options.root, options.rootMargin]);

	return isInView;
}
