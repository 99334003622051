import * as React from "react";
import * as Accordion from "@radix-ui/react-accordion";
import clsx from "clsx";
import type {
	ProductPackages as Props,
	ProductDetail as DetailProps,
} from "@/types/generated/schema";
import { ChevronRightIcon, CheckIcon } from "icons/components";
import Container from "@components/container";
import Heading from "@components/heading";
import SimpleBlockContent from "@components/SimpleBlockContent";
import sx from "./product-packages.module.css";
import IconPickerRenderer from "@components/icon-picker";
import { AnchorButton } from "@components/anchor-button";

function ProductPackages({ title, intro, products }: Props): JSX.Element {
	return (
		<Container>
			<Heading as="h3">{title}</Heading>
			<p className="my-4 text-sm">{intro}</p>

			<div className="grid gap-6 grid-cols-1 lg:grid-cols-3">
				{products.map((item) => (
					<ProductDetail key={item._key} {...item} />
				))}
			</div>
		</Container>
	);
}

export default ProductPackages;

const ProductDetail: React.FC<React.PropsWithChildren<DetailProps>> = ({
	block_id,
	title,
	icons,
	content,
	color,
	accordionItems,
}) => {
	const headingColorClassName = {
		gold: "bg-primary text-black",
		gray: "bg-darkGray text-white",
		black: "bg-black text-white",
	};

	const subjectLink = new URLSearchParams();
	subjectLink.set("subject", block_id);

	return (
		<div id={block_id}>
			<div className={clsx(headingColorClassName[color], "flex flex-col p-10")}>
				<div className="flex">
					{icons.flatMap((item, index) => {
						if (!item.icon) return [];
						const isLastLink = index === icons.length - 1;

						return (
							<div
								key={item._key}
								className={clsx(
									"flex",
									color === "gold" ? "text-black" : "text-white",
								)}
							>
								<IconPickerRenderer icon={item.icon} aria-hidden="true" />
								{icons.length !== 0 && !isLastLink && (
									<span className="mx-2 font-bold" aria-hidden="true">
										+
									</span>
								)}
							</div>
						);
					})}
				</div>
				<Heading as="h3" size="small">
					<SimpleBlockContent blocks={title} />
				</Heading>
			</div>
			<div
				className={clsx("flex flex-col py-8 text-sm bg-lightGray space-y-2", {
					["px-8"]: !accordionItems || accordionItems?.length === 0,
				})}
			>
				{accordionItems && (
					<Accordion.Root type="single">
						{accordionItems.flatMap((item) => {
							if (!item) return [];

							const LeftIcon = () =>
								item.content ? (
									<ChevronRightIcon
										className={clsx(sx.chevron, "flex-shrink-0 mr-2")}
										aria-hidden
									/>
								) : (
									<CheckIcon
										width={14}
										height={14}
										className="flex-shrink-0 self-start mr-2 text-primary"
										aria-hidden
									/>
								);

							return (
								<Accordion.Item
									key={item._key}
									className="w-full"
									value={item._key}
								>
									<Accordion.Header>
										<Accordion.Trigger className="flex items-center px-6 py-4 w-full text-left font-bold focus:outline-none uppercase focus:ring-2 focus:ring-primaryDark focus:ring-opacity-75">
											<LeftIcon />

											{item.title}
										</Accordion.Trigger>
									</Accordion.Header>
									<Accordion.Content className={clsx(item.content && "px-12")}>
										{item.content && (
											<SimpleBlockContent blocks={item.content} />
										)}
									</Accordion.Content>
								</Accordion.Item>
							);
						})}
					</Accordion.Root>
				)}
				{(!accordionItems || accordionItems?.length === 0) && (
					<SimpleBlockContent blocks={content} />
				)}
			</div>
			<div className="flex justify-end py-4">
				<AnchorButton
					variant="dark"
					href={`/contact?${subjectLink.toString()}`}
				>
					Ik kies deze baron!
				</AnchorButton>
			</div>
		</div>
	);
};
