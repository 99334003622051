import clsx from "clsx";
import SimpleBlockContent from "@components/SimpleBlockContent";
import Image from "next/image";
import imageUrlBuilder from "@sanity/image-url";
import client from "../../../client";
import sx from "./testimonial.module.css";
import type { Review as ReviewType } from "@/types/generated/schema";

const builder = imageUrlBuilder(client);

export function Testimonial(review: ReviewType): JSX.Element {
	return (
		<blockquote className="flex flex-col justify-center mt-10 py-10">
			<div
				className={clsx(
					sx.textShadow,
					"inline-flex mx-auto max-w-3xl text-center text-white text-sm font-medium leading-6",
				)}
			>
				<SimpleBlockContent blocks={review.reviewText} />
			</div>
			<footer className="mt-8">
				<div className="md:flex md:items-center md:justify-center">
					<div className="md:flex-shrink-0">
						{review.clientImage?.asset && (
							<Image
								className="mx-auto w-20 h-20 rounded-full object-cover"
								// @ts-ignore
								src={builder
									.image(review.clientImage.asset)
									.auto("format")
									.width(80)
									.height(80)
									.quality(85)
									.url()}
								alt={review.customerName}
								width={80}
								height={80}
							/>
						)}
					</div>
					<div className="items-center mt-3 md:flex md:flex-col md:ml-8 md:mt-0 md:text-left">
						<div className="w-full text-primary text-xl font-bold uppercase">
							{review.customerName}
						</div>
						<div className="w-full text-primary text-lg font-medium">
							{review.companyName}
						</div>
					</div>
				</div>
			</footer>
		</blockquote>
	);
}
