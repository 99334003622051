import * as React from "react";
import { useInView } from "@/utils/use-in-view";
import sx from "./auto-scroller.module.css";
// eslint-disable-next-line @typescript-eslint/ban-types
type EmptyProps = {};
type WithChildren<T = EmptyProps> = T & { children?: React.ReactNode };
type AutoScrollerProps = WithChildren<{ pixelsPerSecond?: number }>;

export function AutoScroller({
	children,
	pixelsPerSecond = 100,
}: AutoScrollerProps): JSX.Element {
	const containerRef = React.useRef<HTMLDivElement>(null);
	const scrollerRef = React.useRef<HTMLDivElement>(null);
	const [scrollerWidth, setScrollerWidth] = React.useState(0);
	const [scrollerScrollWidth, setScrollerScrollWidth] = React.useState(0);
	const isInView = useInView(containerRef, { threshold: 0.4 });
	const scrollUntilLastElementIsFullyVisible =
		-scrollerScrollWidth + scrollerWidth;
	const animationDuration =
		Math.abs(scrollUntilLastElementIsFullyVisible) / pixelsPerSecond;

	React.useEffect(function determineScrollerWidth() {
		setScrollerWidth(scrollerRef.current?.clientWidth ?? 0);
		setScrollerScrollWidth(scrollerRef.current?.scrollWidth ?? 0);
	}, []);

	return (
		<div className="overflow-hidden" ref={containerRef}>
			<div
				ref={scrollerRef}
				className={sx.scroller}
				style={
					{
						"--scroll-width": `${scrollUntilLastElementIsFullyVisible}px`,
						"--animation-play-state": isInView ? "playing" : "paused",
						"--animation-duration": `${animationDuration}s`,
					} as React.CSSProperties
				}
			>
				{children}
			</div>
		</div>
	);
}
