import * as React from "react";
import imageUrlBuilder from "@sanity/image-url";
import Image from "next/image";
import { AnchorButton } from "../../anchor-button";
import { DereferenceRoutes, resolveLink } from "../../../utils/link";
import { CtaSection } from "../../../types/generated/schema";
import client from "../../../../client";
import sx from "./call-to-action.module.css";
const builder = imageUrlBuilder(client);

function CallToAction({
	heading,
	buttonText,
	route,
	image,
	subHeading,
	routeLinkTo,
}: DereferenceRoutes<CtaSection>): JSX.Element {
	const subjectParams = new URLSearchParams();
	if (routeLinkTo?.subject) subjectParams.set("subject", routeLinkTo.subject);
	const link = `${resolveLink(route)}${
		routeLinkTo?.subject ? `?${subjectParams.toString()}` : ""
	}`;

	return (
		<div className="relative flex flex-row items-center justify-center px-6 py-20 m-auto bg-primary">
			{image && (
				<>
					<div className="absolute top-0 left-0 hidden w-3/12 h-full lg:block">
						<Image
						  /* @ts-ignore image.asset.url is already dereferenced */
							src={image.asset.url}
							layout="fill"
							className="object-cover"
							alt=""
						/>
						<div className={sx.fadeImage} />
					</div>
					<div className="flex self-start flex-shrink-0 w-20 h-20 mr-8 lg:hidden">
						<Image
							className="object-cover mx-auto rounded-full lg:hidden"
							// @ts-ignore
							src={builder
								.image(image.asset)
								.auto("format")
								.width(80)
								.height(80)
								.quality(85)
								.url()}
							alt={subHeading}
							objectFit="cover"
							width={80}
							height={80}
						/>
					</div>
				</>
			)}
			<div className="z-10 flex flex-col items-start justify-center space-y-6 lg:flex-row lg:items-center lg:space-x-12 lg:space-y-0">
				<div className="flex flex-col">
					<h3 className="text-xl font-bold text-black uppercase">{heading}</h3>
					<span className="text-sm font-bold text-white">{subHeading}</span>
				</div>

				{route && (
					<AnchorButton href={link} variant="light">
						{buttonText ?? "Klik hier"}
					</AnchorButton>
				)}
			</div>
		</div>
	);
}

export default CallToAction;
