import { useRef } from "react";
import {
	motion,
	useSpring,
	useTransform,
	useViewportScroll,
} from "framer-motion";
import { useElementLocationInfo, useDocumentHeight } from "@/utils/animation";
import { MovingIconWrapper } from "@components/sections/packagesSection";
import type { ReviewSection as SanityProps } from "@/types/generated/schema";
import { Testimonial } from "@components/testimonial";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import sx from "@components/testimonial/testimonial.module.css";
import imageUrlBuilder from "@sanity/image-url";
import client from "../../../client";
import clsx from "clsx";

const builder = imageUrlBuilder(client);
const MovingIcon = motion(MovingIconWrapper);

function ReviewSection(reviewSection: SanityProps): JSX.Element {
	const containerRef = useRef<HTMLDivElement>(null);
	const { offsetTopPixels: fromPixel } = useElementLocationInfo(containerRef);
	const { scrollY } = useViewportScroll();
	const documentHeight = useDocumentHeight();

	const transform = useTransform(
		scrollY,
		[fromPixel - documentHeight, fromPixel + documentHeight],
		[-125, 775],
	);
	const physics = { damping: 20, mass: 0.27, stiffness: 100 };
	const spring = useSpring(transform, physics);

	return (
		<div className="relative" ref={containerRef}>
			<motion.div className="absolute z-20 right-10 hidden text-primary pointer-events-none sm:flex">
				<MovingIcon
					style={{
						y: spring,
					}}
				/>
			</motion.div>
			<section className="relative py-12 bg-gray-50 overflow-hidden md:py-20 lg:py-24">
				<div className={clsx(sx.bgOverlay)}>
					<img
						className="absolute top-0 w-full object-cover"
						src={builder.image(reviewSection.backgroundImage).quality(80).url()}
						aria-hidden
						alt=""
					/>
				</div>
				<div className="relative mx-auto px-4 max-w-7xl sm:px-6 lg:px-8">
					<Carousel showStatus={false} showThumbs={false}>
						{reviewSection.reviews.map((review) => (
							// @ts-ignore Review is dereferenced
							<Testimonial key={review._id} {...review} />
						))}
					</Carousel>
				</div>
			</section>
		</div>
	);
}

export default ReviewSection;
