import * as React from "react";
import type { LatestPropositionsSection as Props } from "@/types/generated/schema";
import SimpleBlockContent from "@components/SimpleBlockContent";
import { AnchorButton } from "@components/anchor-button";
import { Proposition } from "@components/proposition";

function LatestPropositions({
	title,
	description,
	properties,
}: Props): JSX.Element {
	if (!properties || properties.length === 0) {
		return (
			<div className="mx-auto px-10 py-32 max-w-8xl">
				<div className="flex flex-col col-span-1 py-10">
					<div className="mb-4 text-xl font-bold uppercase">
						<SimpleBlockContent blocks={title} />
					</div>
					<span className="mb-8 text-sm">{description}</span>
					<div className="inline-flex">
						<AnchorButton href="/aanbod" variant="dark">
							Aanbod bekijken
						</AnchorButton>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className="mx-auto px-10 py-32 max-w-8xl">
			<div className="grid lg:hidden">
				<div className="flex flex-col col-span-1 py-10">
					<div className="mb-8 text-xl font-bold uppercase">
						<SimpleBlockContent blocks={title} />
					</div>
					<span className="mb-8 text-sm">{description}</span>
					<div className="inline-flex">
						<AnchorButton href="/aanbod" variant="dark">
							Aanbod bekijken
						</AnchorButton>
					</div>
				</div>
				<div className="col-span-1">
					<div className="grid gap-6 grid-cols-1 md:grid-cols-2">
						{properties &&
							properties.flatMap((proposition) => {
								if (!proposition) return [];

								return (
									// @ts-ignore already dereferenced
									<Proposition key={proposition.id} {...proposition} />
								);
							})}
					</div>
				</div>
			</div>
			<div className="hidden grid-cols-2 lg:grid">
				<div className="col-span-1">
					<div className="grid gap-6 grid-cols-2">
						{properties &&
							properties.flatMap((proposition) => {
								if (!proposition) return [];

								return (
									// @ts-ignore already dereferenced
									<Proposition key={proposition.id} {...proposition} />
								);
							})}
					</div>
				</div>
				<div className="flex flex-col col-span-1 px-24 py-10">
					<div className="mb-8 text-xl font-bold uppercase">
						<SimpleBlockContent blocks={title} />
					</div>
					<span className="mb-8 text-sm">{description}</span>
					<div className="inline-flex">
						<AnchorButton href="/aanbod" variant="dark">
							Aanbod bekijken
						</AnchorButton>
					</div>
				</div>
			</div>
		</div>
	);
}

export default LatestPropositions;
