import * as React from "react";
import clsx from "clsx";
import { useForm, SubmitHandler } from "react-hook-form";
import * as Checkbox from "@radix-ui/react-checkbox";
import * as Label from "@radix-ui/react-label";
import { Listbox, Transition } from "@headlessui/react";
import Heading from "../heading";
import SimpleBlockContent from "@components/SimpleBlockContent";
import { CheckIcon } from "icons/components";
import { useRouter } from "next/router";
import type { ContactForm as RootContactFormTypes } from "@/types/generated/schema";
import { BaronLogoFlag } from "@components/baron-logo-flag";

export type ContactFormInputs = {
	firstName: string;
	lastName: string;
	email: string;
	message: string;
	subject: string;
	phoneNumber?: string;
	company?: string;
};

type Subject = {
	id: string;
	name: string;
};

const subjects: Subject[] = [
	{ id: "algemeen", name: "Algemeen" },
	{ id: "advies-huurprijs", name: "Advies huurprijs" },
	{ id: "vastgoed-verhuren", name: "Vastgoed Verhuren" },
	{ id: "nieuwbouw-verkopen", name: "Nieuwbouw verkopen" },
	{ id: "opstart-vve", name: "Uw Opstart Baron - VVE-Beheer" },
	{ id: "administratief-vve", name: "Uw Administratieve Baron - VVE-Beheer" },
	{ id: "technisch-vve", name: "Uw Technische Baron - VVE-Beheer" },
	{ id: "administratief-beheer", name: "Uw Administratieve Baron - Beheer" },
	{ id: "technisch-beheer", name: "Uw Technische Baron - Beheer" },
	{ id: "compleet-beheer", name: "Uw Complete Baron - Beheer" },
];

const ContactForm: React.FC<React.PropsWithChildren<RootContactFormTypes>> = ({
	title,
	contactInfo,
}) => {
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<ContactFormInputs>();
	const [selectedSubject, setSelectedSubject] = React.useState<Subject>(
		subjects[0],
	);
	const [formSubmittedSuccessfully, setFormSubmittedSuccessfully] =
		React.useState(false);
	const { query } = useRouter();

	React.useEffect(() => {
		if (!query["subject"]) return;
		if (subjects.some((i) => i.id === query["subject"]))
			setSelectedSubject(
				subjects[subjects.findIndex((i) => i.id === query["subject"])],
			);
	}, [query]);

	const onSubmit: SubmitHandler<ContactFormInputs> = async (data) => {
		const res = await fetch("/api/submit-form", {
			body: JSON.stringify({
				...data,
			}),
			headers: {
				"Content-Type": "application/json",
			},
			method: "POST",
		});

		const result = await res.json();

		if (result.success) {
			reset();
			setFormSubmittedSuccessfully(true);

			return true;
		}

		return false;
	};

	if (formSubmittedSuccessfully) {
		return (
			<div className="m-auto px-8 py-24 max-w-6xl xl:px-0">
				<Heading className="mb-6 font-rigBold text-3xl" as="p">
					Bedankt voor uw inzending.
				</Heading>
				<p className="max-w-2xl text-sm">
					Er zal zo spoedig mogelijk contact met u worden opgenomen.
				</p>
			</div>
		);
	}

	return (
		<div className="grid-col-1 grid gap-28 m-auto px-8 py-24 max-w-6xl lg:grid-cols-2 xl:px-0">
			<div className="col-span-1">
				<Heading className="mb-6 font-rigBold text-3xl" as="p">
					{title}
				</Heading>
				<form
					onSubmit={handleSubmit(onSubmit)}
					className="grid gap-y-6 grid-cols-1 sm:gap-x-8 sm:grid-cols-2"
				>
					<div>
						<label
							htmlFor="firstName"
							className="block text-gray-700 font-rigBold text-sm font-bold"
						>
							Voornaam *
						</label>
						<div className="relative mt-1 border border-gray-300 rounded-md">
							<input
								{...register("firstName", { required: true })}
								autoComplete="given-name"
								className="block px-4 py-3 w-full border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary"
							/>
						</div>
					</div>
					<div>
						<label
							htmlFor="lastName"
							className={clsx(
								"block text-gray-700 font-rigBold text-sm font-bold",
							)}
						>
							Achternaam *
						</label>
						<div className="relative mt-1 border border-gray-300 rounded-md">
							<input
								{...register("lastName", { required: true })}
								autoComplete="family-name"
								className="block px-4 py-3 w-full border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary"
							/>
						</div>
					</div>
					<div className="sm:col-span-2">
						<label
							htmlFor="company"
							className="block text-gray-700 font-rigBold text-sm font-bold"
						>
							Bedrijfsnaam (optioneel)
						</label>
						<div className="relative mt-1 border border-gray-300 rounded-md">
							<input
								{...register("company")}
								autoComplete="organization"
								className="block px-4 py-3 w-full border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary"
							/>
						</div>
					</div>
					<div className="sm:col-span-2">
						<label
							htmlFor="email"
							className="block text-gray-700 font-rigBold text-sm font-bold"
						>
							E-mail *
						</label>
						<div className="relative mt-1 border border-gray-300 rounded-md">
							<input
								{...register("email", { required: true })}
								autoComplete="email"
								className="block px-4 py-3 w-full border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary"
							/>
						</div>
					</div>
					<div className="sm:col-span-2">
						<label
							htmlFor="phoneNumber"
							className="block text-gray-700 font-rigBold text-sm font-bold"
						>
							Telefoonnummer (optioneel)
						</label>
						<div className="relative mt-1 border border-gray-300 rounded-md">
							<input
								{...register("phoneNumber")}
								autoComplete="tel"
								className="block px-4 py-3 w-full border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary"
								placeholder="+31 612345678"
							/>
						</div>
					</div>
					<div className="sm:col-span-2">
						<Listbox value={selectedSubject} onChange={setSelectedSubject}>
							{({ open }) => (
								<>
									<Listbox.Label className="block text-gray-700 font-rigBold text-sm font-bold">
										Betreft dienst
									</Listbox.Label>
									<div className="relative mt-1">
										<Listbox.Button className="pl-3 pr-10 py-3 w-full text-left bg-white border border-gray-300 focus:border-primary rounded-md focus:outline-none shadow-sm cursor-default focus:ring-1 focus:ring-primary sm:text-sm">
											<span className="block truncate">
												{selectedSubject.name}
											</span>
										</Listbox.Button>

										<Transition
											show={open}
											as={React.Fragment}
											leave="transition ease-in duration-100"
											leaveFrom="opacity-100"
											leaveTo="opacity-0"
										>
											<Listbox.Options
												static
												className="absolute z-10 mt-1 py-1 w-full max-h-60 text-base bg-white rounded-md focus:outline-none shadow-lg overflow-auto ring-1 ring-black ring-opacity-5 sm:text-sm"
											>
												{subjects.map((subject) => (
													<Listbox.Option
														key={subject.id}
														id={subject.id}
														className={({ active }) =>
															clsx(
																active
																	? "text-white bg-primary"
																	: "text-gray-900",
																"relative pl-3 pr-9 py-2 cursor-default select-none",
															)
														}
														value={subject}
													>
														{({ selected, active }) => (
															<>
																<span
																	className={clsx(
																		selected ? "font-semibold" : "font-normal",
																		"block truncate",
																	)}
																>
																	{subject.name}
																</span>

																{selected ? (
																	<span
																		className={clsx(
																			active ? "text-white" : "text-primary",
																			"absolute inset-y-0 right-0 flex items-center pr-4",
																		)}
																	>
																		<CheckIcon
																			className="w-5 h-5"
																			aria-hidden="true"
																		/>
																	</span>
																) : null}
															</>
														)}
													</Listbox.Option>
												))}
											</Listbox.Options>
										</Transition>
									</div>
								</>
							)}
						</Listbox>
					</div>
					<div className="sm:col-span-2">
						<label
							htmlFor="message"
							className="block text-gray-700 font-rigBold text-sm font-bold"
						>
							Bericht *
						</label>
						<div className="mt-1">
							<textarea
								{...register("message", { required: true })}
								rows={4}
								className="block px-4 py-3 w-full border border-gray-300 rounded-md focus:outline-none shadow-sm focus:ring focus:ring-primary"
								defaultValue={""}
							/>
						</div>
					</div>
					<div className="sm:col-span-2">
						<div className="flex items-start">
							<div className="flex-shrink-0">
								<Checkbox.Root
									id="acceptTerms"
									className="w-4 h-4 text-primary rounded focus:outline-none focus:ring ring-1 ring-primary"
									required
								>
									<Checkbox.Indicator asChild>
										<CheckIcon />
									</Checkbox.Indicator>
								</Checkbox.Root>
							</div>
							<div className="ml-3">
								<Label.Root
									htmlFor="acceptTerms"
									className="text-gray-500 font-rigMedium text-base"
								>
									Ik ga akkoord met de{" "}
									<a
										href="/privacy-voorwaarden"
										target="_blank"
										className="text-gray-700 underline font-medium"
									>
										Privacy Voorwaarden
									</a>
									.
								</Label.Root>
							</div>
						</div>
					</div>
					<div className="sm:col-span-2">
						<button
							type="submit"
							className="inline-flex items-center justify-center px-6 py-3 w-full text-white font-rigBold text-base font-bold bg-primary hover:bg-primaryDark border border-transparent rounded-md focus:outline-none shadow-sm focus:ring-2 focus:ring-primary focus:ring-offset-2"
						>
							Versturen
						</button>
					</div>
				</form>
			</div>
			<div className="col-span-1">
				<div className="relative mb-4 h-20">
					<BaronLogoFlag title="Uw Baron" variant="small" />
				</div>
				{contactInfo && <SimpleBlockContent blocks={contactInfo} />}
			</div>
		</div>
	);
};

export default ContactForm;
