import * as React from "react";
import Image from "next/image";
import imageUrlBuilder from "@sanity/image-url";
import Container from "@components/container";
import Heading from "@components/heading";
import SimpleBlockContent from "@components/SimpleBlockContent";
import type { TextSection as SanityProps } from "@/types/generated/schema";
import client from "../../../client";

const builder = imageUrlBuilder(client);

function TextSection({
	heading,
	label,
	text,
	image,
}: SanityProps): JSX.Element {
	return (
		<Container>
			{image ? (
				<div className="grid gap-10 grid-cols-8">
					<div className="flex flex-col col-span-8 lg:col-span-6">
						<div className="my-6">
							<Heading as="h3">{heading}</Heading>
							{label && <p className="my-6 text-sm">{label}</p>}
						</div>

						{text && <SimpleBlockContent blocks={text} />}
					</div>
					<div className="col-span-8 lg:col-span-2">
						<figure>
							<Image
								layout="responsive"
								src={builder
									.image(image)
									.quality(85)
									.auto("format")
									.width(250)
									.height(250)
									.url()}
								width={250}
								height={250}
								alt=""
							/>
							{image.caption && (
								<figcaption className="pt-4 text-sm font-bold">
									{image.caption}
								</figcaption>
							)}
						</figure>
					</div>
				</div>
			) : (
				<div className="flex flex-col">
					<div className="my-6">
						<Heading as="h3">{heading}</Heading>
						{label && <p className="my-6 text-sm">{label}</p>}
					</div>

					{text && <SimpleBlockContent blocks={text} />}
				</div>
			)}
		</Container>
	);
}

export default TextSection;
