import * as React from "react";
import * as Accordion from "@radix-ui/react-accordion";
import imageUrlBuilder from "@sanity/image-url";
import type {
	AccordionSection as SanityProps,
	Figure,
} from "@/types/generated/schema";
import Image from "next/image";
import clsx from "clsx";
import type { ImageAsset } from "../../../types";
import { ChevronDownIcon } from "icons/components";
import SimpleBlockContent from "@components/SimpleBlockContent";
import Container from "../../container";
import client from "../../../../client";
import sx from "./accordionSection.module.css";

type Props = Omit<SanityProps, "image"> & {
	image?: Omit<Figure, "asset"> & ImageAsset;
};

const builder = imageUrlBuilder(client);

function AccordionSection({
	title,
	intro,
	numberedItems,
	accordionItems,
	image,
}: Props): JSX.Element {
	return (
		<Container>
			<div className="grid gap-12 grid-cols-1 lg:grid-cols-2">
				<div className="col-span-1">
					<div className="mb-4 text-xl font-bold uppercase">
						<SimpleBlockContent blocks={title} />
					</div>
					<p className="mb-6 text-sm">{intro}</p>
					<Accordion.Root
						className={clsx(
							numberedItems && sx.counterReset,
							"flex flex-col space-y-2",
						)}
						type="single"
					>
						{accordionItems.flatMap((item) => {
							if (!item) return [];

							return (
								<Accordion.Item
									key={item._key}
									className="w-full"
									value={item._key}
								>
									<Accordion.Header className="bg-lightGray">
										<Accordion.Trigger className="flex items-center justify-between p-4 w-full text-left text-xs font-bold focus:outline-none uppercase focus:ring-2 focus:ring-primaryDark focus:ring-opacity-75 md:text-sm">
											<span className="inline-flex items-center break-all">
												<div
													className={clsx(
														sx.headerCircle,
														numberedItems && sx.counterContent,
														"hidden sm:flex",
													)}
													aria-hidden
												/>
												{item.title}
											</span>
											<ChevronDownIcon className={sx.chevron} aria-hidden />
										</Accordion.Trigger>
									</Accordion.Header>
									<Accordion.Content className="px-12 py-4 text-sm">
										<SimpleBlockContent blocks={item.content} />
									</Accordion.Content>
								</Accordion.Item>
							);
						})}
					</Accordion.Root>
				</div>

				{image && (
					<div className="col-span-1">
						<Image
							layout="responsive"
							src={builder
								.image(image)
								.quality(85)
								.auto("format")
								.width(600)
								.height(615)
								.url()}
							width={600}
							height={615}
							alt={image.alt}
						/>
						<p className="p-6 text-sm">{image.caption}</p>
					</div>
				)}
			</div>
		</Container>
	);
}

export default AccordionSection;
