import * as React from "react";
import clsx from "clsx";
import sx from "./quote.module.css";

type Props = {
	quote: string;
};

function Quote({ quote }: Props): JSX.Element {
	return (
		<div className={clsx("font-wild")}>
			<p className={sx.quote}>{quote}</p>
		</div>
	);
}

export default Quote;
